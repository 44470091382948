<template>
  <LoadingLayout></LoadingLayout>
</template>

<script>
import LoadingLayout from "@/layouts/LoadingLayout.vue";

export default {
  name: "Oauth",
  components: {
    LoadingLayout
  },
  created() {
    this.$store.commit("user/LOGOUT");
    this.$router.push("/").catch(() => {});
  }
};
</script>

<style scoped lang="scss"></style>
